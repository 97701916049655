// App.js
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Footer from "./components/Footer";
import Contact from "./pages/Contact";
import Nav from "./components/Nav";
import ScrollToTop from "./components/ScrollToTop";
import PortfolioPage from "./pages/PortfolioPage";
import AnimatedCursor from "react-animated-cursor";
function App() {
  return (
    <Router>
      <div className="App">
        <ScrollToTop />
        <Nav />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/portfolio/:id" element={<PortfolioPage />} />
        </Routes>
        <Footer />{" "}
        <AnimatedCursor
          innerSize={13}
          outerSize={20}
          color="0,0,0"
          outerAlpha={0.2}
          innerScale={0.7}
          outerScale={5}
        />
      </div>
    </Router>
  );
}

export default App;

{
  /*  */
}
