import React from "react";
import { FaLinkedin, FaInstagram } from "react-icons/fa6";

function Footer() {
  return (
    <div className="w-screen bg-stone-900 text-stone-200 py-4 flex justify-between items-center px-8">
      <p className="font-header text-subheader ">Nemen</p>
      <div className="flex gap-5 ">
        <FaLinkedin
          size={40}
          className="cursor-pointer transition duration-300 ease-in-out hover:text-stone-600"
        />{" "}
        <FaInstagram
          size={40}
          className="cursor-pointer transition duration-300 ease-in-out hover:text-stone-600"
        />
      </div>
      <div className="flex flex-col text-right">
        <p className="font-subheader text-text ">Långholmsgatan 30</p>
        <p className="font-subheader text-text ">117 33 Stockholm</p>
      </div>
    </div>
  );
}

export default Footer;
