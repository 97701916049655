import React from "react";
import { useParams } from "react-router-dom";
import Vimeo from "@u-wave/react-vimeo";
const projectData = [
  {
    id: 1,
    title: "Utifrån och In",
    client: "Friskis&svettis",
    work: "Förarbete, inspelning & efterbearbetning av reklamfilm.",
    img: "utifranochin.png",
    video: "753725689",
  },
  {
    id: 2,
    title: "Kom närmare 2.0",
    client: "Passion lab & Telia",
    work: "Inspelning och postproduktion av 14 stycken filmer.",
    img: "komnarmre2.png",
    video: "",
  },
  {
    id: 3,
    title: "Higher Education",
    client: "Mentimeter",
    work: "Förarbete och inspelning av reklamfilm.",
    img: "highereducation.png",
    video: "930993849",
  },
  {
    id: 4,
    title: "Älskas av bin",
    client: "Passion lab",
    work: "Inspelning och efterbearbetning av reklamfilm.",
    img: "alskasavbin.png",
    video: "932039877",
  },
  {
    id: 5,
    title: "Väx upp för fan!",
    client: "Passion lab",
    work: "Filmproduktion och postproduktion av säsong ett.",
    img: "vaxupp.png",
    video: "717907863",
  },
  {
    id: 6,
    title: "The Naia Initiative",
    client: "FLB Europa",
    work: "Filmproduktion och postproduktion.",
    img: "naia.png",
    video: "555226191",
  },
  {
    id: 7,
    title: "Lottonytt",
    client: "FLX",
    work: "Postproduktion.",
    img: "lottonytt.png",
    video: "540268590",
  },
  {
    id: 8,
    title: "Vimla Unlocked",
    client: "Hanapee Productions",
    work: "Foto och ljus.",
    img: "vimla.png",
    video: "https://www.youtube.com/watch?v=3wfWN3cG58s&t=2s&ab_channel=Vimla",
  },
  {
    id: 9,
    title: "Kom närmare 1.0",
    client: "Passion lab",
    work: "Filmproduktion och postproduktion..",
    img: "komnarmre1.png",
    video: "932039877",
  },
];

function PortfolioPage() {
  const { id } = useParams();
  const project = projectData.find((p) => p.id === parseInt(id));

  if (!project) return <div>Project not found</div>;

  return (
    <div className="flex flex-col mt-[4rem] items-center h-screen bg-stone-200 text-stone-900">
      {/* Render video player if a video URL is provided */}
      {project.video && (
        <div className="flex justify-center items-center w-auto h-auto flex justify-center mb-4 mt-4 top-0 border-black border-2 py-3 px-4 ">
          <Vimeo
            video={project.video}
            autoplay
            muted
            loop
            color="#fbbf24"
            width={1400}
            height={800}
          />
        </div>
      )}
      <h1 className="text-header font-subheader leading-none text-center">
        "{project.title}"
      </h1>
      <p className="text-text font-text leading-none italic text-center">
        {project.client}
      </p>
      <p className="text-text font-text text-center">{project.work}</p>
    </div>
  );
}

export default PortfolioPage;
