import React from "react";
import PortfolioCard from "./PortfolioCard";
import img1 from "../assets/images/utifranochin.png";
import img2 from "../assets/images/komnarmre2.png";
import img3 from "../assets/images/alskasavbin.png";
import img4 from "../assets/images/highereducation.png";
import img5 from "../assets/images/vaxupp.png";
import img6 from "../assets/images/naia.png";
import img7 from "../assets/images/lottonytt.png";
import img8 from "../assets/images/vimla.png";
import img9 from "../assets/images/komnarmre1.png";

function Portfolio() {
  return (
    <div className="flex justify-center items-center h-full w-screen bg-stone-200 text-stone-900">
      <div className="grid grid-cols-3 justify-center items-center bg-stone-200 text-stone-900">
        <PortfolioCard
          id={1}
          img={img1}
          title={"Utifrån och In"}
          client={"Friskis&svettis"}
          work={"Förarbete, inspelning & efterbearbetning av reklamfilm."}
        />
        <PortfolioCard
          id={2}
          title={"Kom närmare 2.0"}
          client={"Passion lab & Telia"}
          work={"Inspelning och postproduktion av 14 stycken filmer."}
          img={img2}
        />
        <PortfolioCard
          id={3}
          img={img4}
          title={"Higher Education"}
          client={"Mentimeter"}
          work={"Förarbete och inspelning av reklamfilm."}
        />
        <PortfolioCard
          id={4}
          img={img3}
          title={"Älskas av bin"}
          client={"Passion lab"}
          work={"Inspelning och efterbearbetning av reklamfilm."}
        />
        <PortfolioCard
          id={5}
          img={img5}
          title={"Väx upp för fan!"}
          client={"Passion lab"}
          work={"Filmproduktion och postproduktion av säsong ett."}
        />
        <PortfolioCard
          id={6}
          img={img6}
          title={"The Naia Initiative"}
          client={"FLB Europa"}
          work={"Filmproduktion och postproduktion."}
        />
        <PortfolioCard
          id={7}
          img={img7}
          title={"Lottonytt"}
          client={"FLX"}
          work={"Postproduktion."}
        />
        <PortfolioCard
          id={8}
          img={img8}
          title={"Vimla Unlocked"}
          client={"Hanapee Productions"}
          work={"Foto och ljus."}
        />
        <PortfolioCard
          id={9}
          img={img9}
          title={"Kom närmare 1.0"}
          client={"Passion lab"}
          work={"Filmproduktion och postproduktion."}
        />
      </div>
    </div>
  );
}

export default Portfolio;
