import React, { useEffect, useState } from "react";
import Hero from "../components/Hero";
import Partners from "../components/Partners";
import Some from "../components/Some";
import Portfolio from "../components/Portfolio";
import About from "../components/About";
import element1 from "../assets/images/element1.png";
import element2 from "../assets/images/element2.png";
import element3 from "../assets/images/element3.png";

function Home() {
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <>
      {" "}
      <Hero />
      <Some />
      <Portfolio />
      <Partners />
      <About />
    </>
  );
}

export default Home;
{
  /*
      <img
        src={element1}
        alt="Background"
        className="absolute w-[40%] animate-bounce object-contain z-10 bottom-[0%] left-[30%] mix-blend-exclusion  "
        style={{
          transform: `translateY(${scrollY * 0.5}px)`,
          transition: "transform 0.4s ease-out",
        }}
      />
*/
}
