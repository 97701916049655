import React from "react";

function Contact() {
  return (
    <div className="relative h-screen w-full bg-stone-200 flex flex-col items-center justify-center text-stone-900">
      {/* Existing Styling */}
      <div>
        <p className="font-subheader text-subheader text-center">
          Kontakta Oss
        </p>
        <div className="w-[100%] h-[1px] bg-stone-900 top-[3%]" />
        <div className="flex gap-10">
          <div>
            <p className="font-text text-subheader">Tomas Svensson</p>
            <p className="font-text text-text italic">Director/Editor</p>
            <p className="font-text text-text">+46 70 013 03 90</p>
          </div>
          <div>
            <p className="font-text text-subheader">Adam Bassari</p>
            <p className="font-text text-text italic">Producer/DoP</p>
            <p className="font-text text-text">+46 73 726 68 37</p>
          </div>
        </div>
        {/* New Form */}
        <form className="mt-[15%] p-4 rounded-lg">
          <div className="mb-4">
            <label
              className="block font-text text-stone-900 mb-2"
              htmlFor="name"
            >
              Namn
            </label>
            <input
              type="text"
              id="name"
              name="name"
              className="w-full p-2 border border-stone-900 rounded bg-transparent text-stone-900"
              required
            />
          </div>
          <div className="mb-4">
            <label
              className="block font-text text-stone-900 mb-2"
              htmlFor="email"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              className="w-full p-2 border border-stone-900 rounded bg-transparent text-stone-900"
              required
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-stone-900 font-text mb-2"
              htmlFor="message"
            >
              Meddelande
            </label>
            <textarea
              id="message"
              name="message"
              className="w-full p-2 border border-stone-900 rounded bg-transparent text-stone-900"
              rows="4"
              required
            />
          </div>

          <button
            type="submit"
            className=" mt-4 px-8 py-3 border-2 text-text border-stone-900 bg-transparent text-stone-900 font-subheader rounded-lg shadow-lg hover:bg-stone-900 hover:text-stone-200 transition-colors duration-300"
          >
            SKICKA
          </button>
        </form>
      </div>{" "}
    </div>
  );
}

export default Contact;
