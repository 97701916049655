import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

function Nav() {
  const [scrollDirection, setScrollDirection] = useState("up");

  useEffect(() => {
    let lastScrollY = window.scrollY;

    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (currentScrollY > lastScrollY) {
        // User is scrolling down
        setScrollDirection("down");
      } else {
        // User is scrolling up
        setScrollDirection("up");
      }

      lastScrollY = currentScrollY;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className={`fixed top-0 left-0 w-full bg-stone-200 text-stone-900 flex justify-between items-center px-[3%] h-[4rem] z-10 border-b border-black transition-transform duration-300 ${
        scrollDirection === "down" ? "-translate-y-full" : "translate-y-0"
      }`}
    >
      <div>
        <Link to="/" className="flex items-center gap-2">
          <p className="font-header text-subheader transition duration-300 ease-in-out hover:text-stone-600">
            Nemen
          </p>
        </Link>
      </div>
      <div className="flex gap-8">
        <Link to="/" className="flex items-center gap-2 group relative">
          <span className="font-text text-text">Home</span>
          <span className="absolute bottom-0 left-0 w-full h-[2px] bg-stone-900 scale-x-0 group-hover:scale-x-100 transition-transform duration-300 ease-in-out origin-left"></span>
        </Link>

        <Link to="/contact" className="flex items-center gap-2 group relative">
          <span className="font-text text-text">Contact</span>
          <span className="absolute bottom-0 left-0 w-full h-[2px] bg-stone-900 scale-x-0 group-hover:scale-x-100 transition-transform duration-300 ease-in-out origin-left"></span>
        </Link>
      </div>
    </div>
  );
}

export default Nav;
