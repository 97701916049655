import React from "react";
import { useNavigate } from "react-router-dom";

function PortfolioCard({ id, client, title, img, onClick }) {
  const navigate = useNavigate();

  const handleCardClick = () => {
    navigate(`/portfolio/${id}`);
  };

  return (
    <div
      className="flex flex-col w-full justify-center items-center group"
      onClick={handleCardClick}
    >
      <div className="relative flex w-full cursor-pointer">
        <div className="w-[40rem] h-[40rem]">
          <div className="relative w-full h-full overflow-hidden">
            <div className="absolute inset-0 bg-black bg-opacity-35 z-10 group-hover:bg-opacity-10 transition-all duration-300"></div>
            <img
              src={img}
              className="object-cover w-full h-full transition-opacity duration-300  z-0"
            />
          </div>
        </div>
        <div className="absolute left-[50%] top-[50%] text-stone-200 transform -translate-x-1/2 -translate-y-1/2 z-20">
          <h1 className="font-text text-text leading-none">"{title}"</h1>
          <p className="font-text text-text leading-none">//{client}</p>
        </div>
      </div>
    </div>
  );
}

export default PortfolioCard;
