import React from "react";
import { Link } from "react-router-dom";

function About() {
  return (
    <div className="relative h-full w-screen bg-stone-900 flex flex-col justify-center items-center text-stone-900">
      <div className=" z-[10] absolute top-0 left-0 w-full h-[1px] bg-stone-900" />

      <div className="w-[100%] bg-stone-200 flex flex-col items-center relative ">
        <div className="my-[5%] w-[30%]">
          <p className="font-text text-text mb-[4%]">
            Nemens vision är att skapa levande berättelser som känns genuina. Vi
            skräddarsyr produktionen efter ditt varumärke och ser till att
            resultatet lyfter fram din målbild. Vårt fokus är att alltid arbeta
            nära våra kunder från för- till efterproduktion.
          </p>

          <div className="flex justify-center items-center gap-5 mb-4">
            <p className="font-subheader text-text">Långholmsgatan 30</p>
            <div className="w-40 h-[2px] bg-stone-900" />
            <p className="font-subheader text-text">117 33 Stockholm</p>
          </div>

          <div className="flex justify-center items-center">
            <Link to="/contact">
              <button className=" mt-4 px-6 py-3 border-2 text-text border-stone-900 bg-transparent text-stone-900 font-subheader rounded-lg shadow-lg hover:bg-stone-900 hover:text-stone-200 transition-colors duration-300">
                KONTAKTA OSS
              </button>
            </Link>
          </div>
        </div>{" "}
      </div>
    </div>
  );
}

export default About;
