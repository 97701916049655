import React, { useEffect, useState } from "react";
import Wave from "react-wavify";
import element1 from "../assets/images/element1.png";

function Hero() {
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const circleSize = Math.min(300, scrollY * 0.6);

  return (
    <div className="relative h-screen w-screen bg-stone-200 flex flex-col justify-center items-center text-stone-900 overflow-hidden">
      <p
        className="font-subheader text-text relative z-10"
        style={{
          transform: `translateY(-${scrollY * 0.3}px)`,
          transition: "transform 0.1s ease-out",
        }}
      >
        Produktionsbolag baserat i Stockholm
      </p>

      <h1
        className="font-header text-header relative z-10"
        style={{
          transform: `translateY(-${scrollY * 0.2}px)`,
          transition: "transform 0.1s ease-out",
        }}
      >
        NEMEN
      </h1>

      <div className="flex justify-center items-center gap-5 relative z-10">
        <p
          className="font-subheader text-text"
          style={{
            transform: `translateY(${scrollY * 0.3}px)`,
            transition: "transform 0.1s ease-out",
          }}
        >
          För
        </p>

        <div
          className="w-40 h-[2px] bg-stone-900"
          style={{
            transform: `translateY(${scrollY * 0.25}px)`,
            transition: "transform 0.1s ease-out",
          }}
        />

        <p
          className="font-subheader text-text"
          style={{
            transform: `translateY(${scrollY * 0.3}px)`,
            transition: "transform 0.1s ease-out",
          }}
        >
          till efterproduktion
        </p>
      </div>
      <div
        className="absolute bottom-[10%] w-[1rem] h-[1rem] bg-black rounded-full animate-bounce"
        style={{
          animation: "floatUpDown 3s ease-in-out infinite", // Smooth up and down motion
        }}
      />

      {/* Growing Circle */}
      <div
        className="absolute bg-white rounded-full mix-blend-difference z-10"
        style={{
          width: `${circleSize}px`,
          height: `${circleSize}px`,
          transition: "width 0.2s ease-out, height 0.2s ease-out",
          bottom: "0%", // adjust position
          left: "50%",
          transform: "translateX(-50%)",
        }}
      />

      <Wave
        fill="#1c1917"
        paused={false}
        style={{
          display: "flex",
          position: "absolute",
          bottom: 0,
          zIndex: 10,
        }}
        options={{
          height: 30,
          amplitude: 50,
          speed: 0.15,
          points: 7,
        }}
      />
    </div>
  );
}

export default Hero;
